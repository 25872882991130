/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.3/dist/css/bootstrap.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/@domchristie/turn@3.1.1/dist/turn.min.css
 * - /npm/sweetalert2@11.11.0/dist/sweetalert2.min.css
 * - /npm/izitoast@1.4.0/dist/css/iziToast.min.css
 * - /npm/swiper@11.1.4/swiper-bundle.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
